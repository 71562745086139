import React, { useState } from "react";
import { Link } from "gatsby";
import { ChevronLeft, ChevronRight } from "lucide-react";
import Layout from '../components/layout/Layout';

const BukuProdi = () => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [currentPart, setCurrentPart] = useState(1);
  const [accessGranted, setAccessGranted] = useState(() => 
    typeof window !== 'undefined' ? 
    sessionStorage?.getItem('bookAccess') === 'granted' : 
    false
  );
  
  const bookParts = [
    {
      id: 1,
      title: "Part I",
      url: "https://online.anyflip.com/xoocc/bysz/index.html"
    },
    {
      id: 2,
      title: "Part II",
      url: "https://online.anyflip.com/xoocc/mmwe/index.html"
    }
  ];

  const checkPassword = () => {
    // Simple frontend password check
    if (password === 'bukuinspiratif') {
      setAccessGranted(true);
      sessionStorage?.setItem('bookAccess', 'granted');
    } else {
      alert('Password Salah!');
      setPassword('');
    }
  };

  /* Commenting out the old API call code
  const checkPassword = async () => {
    try {
      const url = typeof window !== 'undefined'
        ? `${window.location.origin}/api/validatePassword`
        : '/api/validatePassword';
  
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ password })
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      
      if (data.valid) {
        setAccessGranted(true);
        sessionStorage?.setItem('bookAccess', 'granted');
      } else {
        alert('Password Salah!');
        setPassword('');
      }
    } catch (error) {
      console.error('Password check error:', error);
      alert('Error checking password. Please try again.');
    }
  };
  */

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      checkPassword();
    }
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const nextPart = () => {
    if (currentPart < bookParts.length) {
      setCurrentPart(currentPart + 1);
    }
  };

  const previousPart = () => {
    if (currentPart > 1) {
      setCurrentPart(currentPart - 1);
    }
  };

  if (!accessGranted) {
    return (
      <Layout>
        <div className="flex flex-col items-center justify-center min-h-[calc(100vh-400px)] bg-gray-100 py-12">
          <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
            <h2 className="text-2xl font-semibold text-center mb-6">Access Buku Prodi</h2>
            <input
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={e => setPassword(e.target.value)}
              onKeyPress={handleKeyPress}
              className="w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter Password"
            />
            <div className="flex gap-2">
              <button 
                onClick={checkPassword}
                className="w-1/2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Enter
              </button>
              <button 
                onClick={togglePassword}
                className="w-1/2 px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
              >
                {showPassword ? 'Hide' : 'Show'} Password
              </button>
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <div>
      <div className="bg-white shadow-sm">
        <div className="container mx-auto px-4">
          <div className="flex flex-col sm:flex-row items-center justify-between py-2 space-y-4 sm:space-y-0">
            <Link to="/" className="flex items-center">
              <img src="../../../kabimaLogoNew.png" alt="Kabima Logo" className="h-16 w-14 sm:h-16 sm:w-14"/>
              <span className="ml-2 text-lg sm:text-xl font-semibold">Buku Prodi</span>
            </Link>
            
            <div className="flex flex-wrap items-center justify-center gap-2 sm:gap-4 w-full sm:w-auto">
              <button 
                onClick={previousPart}
                disabled={currentPart === 1}
                className={`flex items-center px-2 sm:px-4 py-2 rounded-md text-sm sm:text-base ${
                  currentPart === 1 
                    ? 'bg-gray-200 text-gray-500 cursor-not-allowed' 
                    : 'bg-blue-500 text-white hover:bg-blue-600'
                }`}
              >
                <ChevronLeft className="w-4 h-4 sm:w-5 sm:h-5 mr-1" />
                <span className="hidden sm:inline">Sebelumnya</span>
              </button>
              
              <span className="text-sm sm:text-lg font-medium px-2">
                Bagian {currentPart} dari {bookParts.length}
              </span>
              
              <button 
                onClick={nextPart}
                disabled={currentPart === bookParts.length}
                className={`flex items-center px-2 sm:px-4 py-2 rounded-md text-sm sm:text-base ${
                  currentPart === bookParts.length 
                    ? 'bg-gray-200 text-gray-500 cursor-not-allowed' 
                    : 'bg-blue-500 text-white hover:bg-blue-600'
                }`}
              >
                <span className="hidden sm:inline">Selanjutnya</span>
                <ChevronRight className="w-4 h-4 sm:w-5 sm:h-5 ml-1" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div style={{ width: '100vw', height: '90vh', overflow: 'hidden' }}>
        <iframe
          style={{ width: '100%', height: '100%', border: 'none' }}
          src={bookParts[currentPart - 1].url}
          seamless="seamless"
          scrolling="no"
          frameBorder="0"
          allowTransparency="true"
          allowFullScreen="true"
        />
      </div>
    </div>
  );
};

export default BukuProdi;